<template>
  <div id="page-user-list">
      <vs-button color="success" type="filled" @click="createTopDeals" icon="add" class="mb-5">Create</vs-button>
      <div class="vx-card p-6 vs-con-loading__container" id="div-with-loading">
      <!-- AgGrid Table -->
        <ag-grid-vue
          ref="agGridTable"
          :components="components"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="topdeals.data"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="topdeals.pagination.per_page"        
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
          @filter-changed="onFilterChanged"
          @filter-modified="onFilterModified">
        </ag-grid-vue> <!-- End AgGrid Table -->
        <vs-pagination :total="topdeals.pagination.last_page" :value="topdeals.pagination.current_page" @input="paginate({page: $event})" />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'


// Cell Renderer
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import loaderDivMixin from '../../../mixins/loaderDivMixin';
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    AgGridVue,
    vSelect,
    CellRendererActions
  },
  mixins: [loaderDivMixin],
   computed: {
    ...mapGetters({
      topdeals: 'topdeals/getTopDeals',
      search: 'topdeals/getSearchObj',
    }),
    searchParams(){
        let params = new URLSearchParams();
        params.append("page", this.page);
        (this.search.query.id != '') ? params.append("query[id]", this.search.query.id) : '';
        (this.search.query.title != '') ? params.append("query[title]", this.search.query.title) : '';
        (this.search.query.subtitle != '') ? params.append("query[subtitle]", this.search.query.subtitle) : '';
        (this.search.query.type != '') ? params.append("query[type]", this.search.query.type) : '';

        return params;
    }
  },
   methods: {
    ...mapActions('topdeals', [
      'fetchAndSetTopDeals',
      'setSearchParameters',
      'clearSearchParameters',
    ]),
    paginate(page){
      this.page=page.page;
      this.fetchTopdeals();
    },
    onFilterChanged($event){
      this.setParams($event);
      this.fetchTopdeals();
    },
    onFilterModified($event){
      this.setParams($event);
      this.fetchTopdeals();
    },
    setParams($event){
      let $this = this;
      const searchKeys = Object.keys($event.api.getFilterModel());
      if(searchKeys.length == 0) this.clearSearchParameters();
      searchKeys.forEach(function(key, val){
        $this.setSearchParameters({key: key, value: $event.api.getFilterModel()[key].filter});
      });
    },
    createTopDeals(){
      if(this.topdeals.pagination.total_records < 3){
        this.$router.push('/topdeals/create');
      }else{
        this.$vs.notify({ 
            title: 'Top Deal',
            text: 'Cannot create more than 3 top deals.',
            color: 'danger',
            position: 'top-right'
        });
      }
    },
    fetchTopdeals(){
      this.openLoading();
      this.fetchAndSetTopDeals({query: this.searchParams})
          .then((res) => this.closeLoading())
          .catch((error) => this.closeLoading());
    }
  },
  data() {
    return {

      // AgGrid
      page: 1,
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          filter: 'agNumberColumnFilter',
          width: 150,
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: 'keep'
          }
        },
        {
          headerName: 'Title',
          field: 'title',
          filter: 'agTextColumnFilter',
          width: 250,
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: 'keep'
          }
        },
        {
          headerName: 'Sub Title',
          field: 'subtitle',
          filter: 'agTextColumnFilter',
          width: 200,
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: 'keep'
          }
        },
        {
          headerName: 'Type',
          field: "type",
          filter: true,
          width: 200,
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: 'keep'
          }
        },
        {
          headerName: 'Actions',
          field: 'transactions',
          // width: 150,
          cellRendererFramework: 'CellRendererActions',
        },
      ],

      // Cell Renderer Components
      components: {
        CellRendererActions
      }
    }
  },
}
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
